import styled from '@emotion/styled';
import { colors } from '../../theming/theme';

const Divider = styled.div({
  display: 'inline-block',
  borderRight: 'solid 2px ' + colors.grey300,
  height: '55px',
});

export default Divider;
