import { FC, useEffect, useState } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import styled from '@emotion/styled';
import { Text } from '@alexpireddu/map-ui';
import { Icon as MapIcon } from '@alexpireddu/map-ui';
import uploadImage from '../../../assets/uploadImage.svg';

const Container = styled.div({
  width: '100%',
  borderRadius: 12,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
});

const RemoveButton = styled.button({
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  position: 'absolute',
  right: 16,
  top: 16,
  color: 'white',
  cursor: 'pointer',
  zIndex: 9,
  width: 36,
  height: 36,
});

const UploadRoot = styled.div<{
  hasImage?: boolean;
}>(({ hasImage }) => ({
  width: '100%',
  display: 'flex',
  aspectRatio: '16 / 9',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: '#cfd8dc',
  cursor: 'pointer',
  gap: 12,
  ...(hasImage && {
    backgroundColor: '#000000',
  }),
}));

const Image = styled.img({
  position: 'absolute',
  maxWidth: '100%',
  aspectRatio: '16 / 9',
  objectFit: 'contain',
});

const Add = styled.img({
  width: 70,
});

export type ImageUploadProps = Pick<DropzoneOptions, 'onDropAccepted'> & {
  file?: File;
};

const ImageUpload: FC<ImageUploadProps> = ({ onDropAccepted, file }) => {
  const [URI, setURI] = useState('');
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    onDropAccepted: onDropAccepted,
  });

  useEffect(() => {
    if (file) {
      setURI(URL.createObjectURL(file));
    }

    return () => {
      setURI((last) => {
        URL.revokeObjectURL(last);
        return '';
      });
    };
  }, [file]);

  return (
    <Container>
      <UploadRoot {...getRootProps()} hasImage={Boolean(URI)}>
        {!URI && (
          <>
            <input {...getInputProps()} />
            <Text as="h3" textAlign="center">
              Carica o trascina qui il tuo contenuto
            </Text>
            <Text textAlign="center" color="#607d8b" marginBottom={12}>
              JPEG o PNG - Max 5 MB - Formato 16:9
            </Text>
            <Add src={uploadImage} />
          </>
        )}
        {URI && (
          <>
            <RemoveButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                onDropAccepted && onDropAccepted([], e.nativeEvent);
              }}
            >
              <MapIcon icon="trash" color="#fff" />
            </RemoveButton>
            <Image src={URI} alt="preview of uploaded file" />
          </>
        )}
      </UploadRoot>
    </Container>
  );
};

export default ImageUpload;
