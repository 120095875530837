import styled from '@emotion/styled';
import { FC, HTMLAttributes } from 'react';
import { colors } from '../../theming/theme';
import cns from 'classnames';

export type BadgeVariant = 'info' | 'warning' | 'error' | 'success' | 'neutral';

export interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
  variant: BadgeVariant;
}

const evaluateVariantStyles = (variant: BadgeVariant) => {
  switch (variant) {
    case 'info':
      return colors.interaction300;
    case 'error':
      return colors.danger300;
    case 'success':
      return colors.success300;
    case 'warning':
      return colors.warning300;
    case 'neutral':
      return colors.neutral300;
  }
};

const StyledContainer = styled.div<BadgeProps>(({ variant }) => ({
  borderRadius: '10px',
  backgroundColor: evaluateVariantStyles(variant),
  display: 'inline-block',
  minWidth: '20px',
  minHeight: '20px',
  lineHeight: '18px',
  fontSize: '16px',
  color: colors.white,
  fonWweight: 'lighter',
  padding: '0 5px',
  textAlign: 'center',
}));

const Badge: FC<BadgeProps> = ({
  children,
  variant = 'info',
  className,
  ...rest
}) => (
  <StyledContainer
    variant={variant}
    className={cns('pa__badge', className)}
    {...rest}
  >
    {children}
  </StyledContainer>
);

export default Badge;
