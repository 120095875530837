import { Avatar, Box, Button, Card, Flex } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import _ from 'lodash';
import { FC, useCallback } from 'react';
import { CityUser } from '../../../apis/cityApis';
import { useModalProvider } from '../../../components';
import { useCityParam } from '../../../hooks';
import DeleteEmployeeModal, {
  DeleteEmployeeModalProps,
} from './DeleteEmployeeModal';

export type EmployeeRecordProps = {
  employee: CityUser;
  onEmployeeDeleted?: () => void;
};

const RecordContainer = styled(Card)({
  flexGrow: 10,
  marginBottom: '16px',
});

const UserName = styled.p({
  fontSize: '1rem',
  fontWeight: '700',
  lineHeight: '1.3rem',
});

const UserEmail = styled.p({
  fontSize: '14px',
});

const EmployeeRecord: FC<EmployeeRecordProps> = ({
  employee,
  onEmployeeDeleted,
}) => {
  const { openModal } = useModalProvider();
  const { currentCityId } = useCityParam();

  const closeDeleteModal = useCallback(
    (reload: boolean) => {
      if (reload && _.isFunction(onEmployeeDeleted)) {
        onEmployeeDeleted();
      }
    },
    [onEmployeeDeleted]
  );

  const openDeleteModal = useCallback(() => {
    openModal<DeleteEmployeeModalProps>({
      //lazyImportFn: () => import('./DeleteEmployeeModal'),
      component: DeleteEmployeeModal,
      additionalProps: {
        employee: employee,
        onClose: closeDeleteModal,
        currentCityId: currentCityId!,
      },
    });
  }, [closeDeleteModal, openModal, currentCityId, employee]);

  const fullName = `${employee.name} ${employee.surname}`;

  return (
    <RecordContainer size="md">
      <Flex alignItems={'center'}>
        <Box mr="16px" ml="24px">
          <Avatar name={fullName} className="avatar" variant="light" />
        </Box>
        <Box flexGrow={2}>
          <UserName>{fullName}</UserName>
          <UserEmail>{employee.email}</UserEmail>
        </Box>
        <Box marginRight={'32px'}>
          <Button
            variant="secondary"
            icon="times"
            size="sm"
            onClick={openDeleteModal}
          />
        </Box>
      </Flex>
    </RecordContainer>
  );
};

export default EmployeeRecord;
