import { FC, SVGAttributes } from 'react';
import SvgIcon from './SvgIcon';

const EditIcon: FC<SVGAttributes<SVGElement>> = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    height="48"
    width="48"
    {...props}
  >
    <path d="M9 39h2.2l22.15-22.15-2.2-2.2L9 36.8Zm30.7-24.3-6.4-6.4 2.1-2.1q.85-.85 2.1-.85t2.1.85l2.2 2.2q.85.85.85 2.1t-.85 2.1Zm-2.1 2.1L12.4 42H6v-6.4l25.2-25.2Zm-5.35-1.05-1.1-1.1 2.2 2.2Z" />
  </SvgIcon>
);

export default EditIcon;
