import { FormControl, Hint, Input, Label, Text } from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useFormContext } from 'react-hook-form';
import { CreateEmployeeModalFormValues } from './CreateEmployeeModal';

export interface CreateEmployeeModalFormProps {
  isLoading: boolean;
}

const CreateEmployeeModalForm: FC<CreateEmployeeModalFormProps> = ({
  isLoading,
}) => {
  const { register, formState, getValues } =
    useFormContext<CreateEmployeeModalFormValues>();
  const {
    errors: { name: nameFieldError, surname: surnameFieldError },
  } = formState;

  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Label>Email</Label>
          <Text as="h4">{getValues('email')}</Text>
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <FormControl isInvalid={!_.isNil(nameFieldError)}>
            <Label>Nome</Label>
            <Input
              isFullWidth
              size="sm"
              {...register('name', {
                required: 'Il campo è obbligatorio',
              })}
              disabled={isLoading}
            />
            <Hint invalidMessage={nameFieldError?.message} />
          </FormControl>
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <FormControl isInvalid={!_.isNil(surnameFieldError)}>
            <Label>Cognome</Label>
            <Input
              isFullWidth
              size="sm"
              {...register('surname', {
                required: 'Il campo è obbligatorio',
              })}
              disabled={isLoading}
            />
            <Hint invalidMessage={surnameFieldError?.message} />
          </FormControl>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateEmployeeModalForm;
