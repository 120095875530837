import { Alert, FormControl, Hint, Input, Label } from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useFormContext } from 'react-hook-form';
import { CreateEmployeeModalFormValues } from './CreateEmployeeModal';

export interface CheckUserModalFormProps {
  isLoading: boolean;
  error: boolean;
}

const CheckUserModalForm: FC<CheckUserModalFormProps> = ({
  isLoading,
  error,
}) => {
  const { register, formState } =
    useFormContext<CreateEmployeeModalFormValues>();
  const {
    errors: { email: emailFieldError },
  } = formState;

  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl isInvalid={!_.isNil(emailFieldError)}>
            <Label>Email</Label>
            <Input
              isFullWidth
              size="sm"
              {...register('email', {
                required: 'Il campo è obbligatorio',
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Il formato della mail non è corretto',
                },
              })}
              disabled={isLoading}
            />
            <Hint invalidMessage={emailFieldError?.message} />
          </FormControl>
        </Col>
        {error && (
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Alert variant="warning" mt={'16px'}>
              L'utente risulta già invitato in questo comune.
            </Alert>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default CheckUserModalForm;
