import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NotificationManager,
  Text,
} from '@alexpireddu/map-ui';
import { FC, useCallback, useState } from 'react';
import { deleteUserRole } from '../../../actions';
import { CityUser } from '../../../apis/cityApis';
import { Roles } from '../../../enums';
import { useAction } from '../../../hooks';
import { ReloadCallbackHandler } from '../../../infrastructure/commonTypes';

export interface DeleteEmployeeModalProps {
  onClose: ReloadCallbackHandler;
  employee: CityUser;
  currentCityId: string;
}

const DeleteEmployeeModal: FC<DeleteEmployeeModalProps> = ({
  onClose,
  employee,
  currentCityId,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const deleteEmployee = useAction(deleteUserRole);

  const handleConfirm = useCallback(() => {
    setLoading(true);
    deleteEmployee(employee.id, {
      role: Roles.CITY_ADMIN,
      entity_related_role_id: currentCityId,
    })
      .then(() => {
        setLoading(false);

        NotificationManager.notify({
          type: 'success',
          message: 'Collaboratore rimosso',
        });

        onClose(true);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [currentCityId, deleteEmployee, employee.id, onClose]);

  return (
    <Modal>
      <ModalHeader>Elimina collaboratore</ModalHeader>
      <ModalBody>
        <Text fontSize={'1.2rem'}>
          Rimuovere{' '}
          <span style={{ fontWeight: '700' }}>
            "{employee.name} {employee.surname}"
          </span>{' '}
          dai collaboratori?
        </Text>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="secondary"
          onClick={() => onClose(false)}
          disabled={isLoading}
        >
          Annulla
        </Button>
        <Button variant="primary" disabled={isLoading} onClick={handleConfirm}>
          Elimina
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteEmployeeModal;
