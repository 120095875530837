import styled from '@emotion/styled';
import { FC, SVGAttributes } from 'react';
import { colors } from '../../theming/theme';
import SvgIcon from './SvgIcon';

const NewCitySvg = styled(SvgIcon)({
  margin: '-5px',
  marginRight: '8px',
  width: '22px',
  height: 'auto',
  fill: colors.white,
  color: colors.white,
});

const NewCityIcon: FC<SVGAttributes<SVGElement>> = (props) => (
  <NewCitySvg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 63.2 56.7"
    width="16px"
    {...props}
  >
    <path
      d="M63.2,17.2c-0.1,0-0.2,0-0.3,0c-15.8,0-31.5,0-47.3,0c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.1,0-0.2
	c0-1.2,0-2.3,0-3.5c0-0.1,0-0.2,0.1-0.2c4.2-2.3,8.3-4.7,12.5-7c3.7-2.1,7.4-4.1,11-6.2c0.2-0.1,0.3-0.1,0.4,0
	C46.3,3.9,53.1,7.7,60,11.6c1,0.6,2,1.1,3,1.7c0.1,0,0.1,0.1,0.2,0.1C63.2,14.6,63.2,15.9,63.2,17.2z M54.5,13.1
	c-0.1,0-0.1-0.1-0.1-0.1c-5-2.8-9.9-5.6-14.9-8.4c-0.1-0.1-0.2,0-0.3,0c-5,2.8-9.9,5.6-14.9,8.3c-0.1,0-0.1,0.1-0.2,0.1
	C34.2,13.1,44.3,13.1,54.5,13.1z"
    />
    <path
      d="M0,43.2c0.1,0,0.2,0,0.3,0c2.7,0,5.5,0,8.2,0c0.1,0,0.2,0,0.3,0c0-2.9,0-5.8,0-8.7c1.6,0,3.2,0,4.8,0
	c0,2.9,0,5.8,0,8.7c2.9,0,5.8,0,8.7,0c0,1.6,0,3.2,0,4.8c-2.9,0-5.8,0-8.7,0c0,0.1,0,0.2,0,0.2c0,2.8,0,5.5,0,8.3c0,0.1,0,0.2,0,0.2
	c-1.6,0-3.2,0-4.7,0c0-0.1,0-0.2,0-0.2c0-2.8,0-5.5,0-8.3c0-0.1,0-0.1,0-0.2c-2.9,0-5.8,0-8.7,0C0,46.4,0,44.8,0,43.2z"
    />
    <path
      d="M63.2,48c-12.2,0-24.4,0-36.6,0c0-1.6,0-3.2,0-4.8c0.1,0,0.1,0,0.2,0c12,0,24.1,0,36.1,0c0.1,0,0.1,0,0.2,0
	C63.2,44.8,63.2,46.4,63.2,48z"
    />
    <path d="M41.4,40.2c-1.4,0-2.9,0-4.3,0c0-6.7,0-13.4,0-20.2c1.4,0,2.9,0,4.3,0C41.4,26.7,41.4,33.4,41.4,40.2z" />
    <path d="M51.9,20c1.4,0,2.9,0,4.3,0c0,6.7,0,13.5,0,20.2c-1.4,0-2.9,0-4.3,0C51.9,33.5,51.9,26.7,51.9,20z" />
    <path d="M26.6,40.2c-1.4,0-2.9,0-4.3,0c0-6.7,0-13.5,0-20.2c1.4,0,2.9,0,4.3,0C26.6,26.7,26.6,33.4,26.6,40.2z" />
  </NewCitySvg>
);

export default NewCityIcon;
